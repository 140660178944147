var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"tp","title":"tulum.party"},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
            name: 'Home',
            icon: 'tim-icons icon-molecule-40',
            path: '/',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Beach Clubs',
            icon: 'tim-icons icon-support-17',
            path: '/tulum-day-beach-clubs',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Blog',
            icon: 'tim-icons icon-map-big',
            path: '/blog',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Clubs',
            icon: 'tim-icons icon-tie-bow',
            path: '/tulum-clubs',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Calendar',
            icon: 'tim-icons icon-calendar-60',
            path: '/tulum-party-mexico-event-calendar',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'DJ SCHEDULE',
            icon: 'tim-icons icon-headphones',
            path: '/tulum-dj-schedule',
          }}}),_c('sidebar-item',{attrs:{"link":{
           icon: 'tim-icons icon-compass-05',
           name: 'Nightlife',
            path: '/tulum-nightlife',
          }}}),_c('sidebar-item',{attrs:{"link":{
           icon: 'tim-icons-astronaut',
           name: 'Travel & Concierge',
            path: '/tulum-concierge-services',
          }}})]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('Transition',{attrs:{"name":"fade","appear":""}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }