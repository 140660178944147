<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" >
    <notifications></notifications>
    <sidebar-fixed-toggle-button/>
    <side-bar
      :background-color="sidebarBackground"
      short-title="tp"
      title="tulum.party"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: 'Home',
            icon: 'tim-icons icon-molecule-40',
            path: '/',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Beach Clubs',
            icon: 'tim-icons icon-support-17',
            path: '/tulum-day-beach-clubs',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Blog',
            icon: 'tim-icons icon-map-big',
            path: '/blog',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Clubs',
            icon: 'tim-icons icon-tie-bow',
            path: '/tulum-clubs',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Calendar',
            icon: 'tim-icons icon-calendar-60',
            path: '/tulum-party-mexico-event-calendar',
          }"
        >
        </sidebar-item>


        <sidebar-item
          :link="{
            name: 'DJ SCHEDULE',
            icon: 'tim-icons icon-headphones',
            path: '/tulum-dj-schedule',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
           icon: 'tim-icons icon-compass-05',
           name: 'Nightlife',
            path: '/tulum-nightlife',
          }"
        >
        </sidebar-item>
<!--        <sidebar-item-->
<!--          :link="{-->
<!--           icon: 'tim-icons icon-video-66',-->
<!--           name: 'Memories',-->
<!--            path: '/tulum-tv',-->
<!--          }"-->
<!--        >-->
<!--        </sidebar-item>-->
        <sidebar-item
          :link="{
           icon: 'tim-icons-astronaut',
           name: 'Travel & Concierge',
            path: '/tulum-concierge-services',
          }"
        >
        </sidebar-item>

      </template>
    </side-bar>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <Transition name="fade" appear>
          <router-view></router-view>
        </Transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import {FadeTransition} from 'vue2-transitions';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
